<template>
  <div class="quiz-page">
    <v-card v-if="!isLoading && user.id" flat max-width="820" class="mx-auto">
      <v-row
        no-gutters
        :style="minHeight"
        style="flex-direction: column; align-items: flex-start"
        class="sw-pt-11"
      >
        <v-col cols="12">
          <div class="sw-block-title text-center">
            {{ pageTitle }}
          </div>
        </v-col>
        <v-col v-if="!isFormVisible" cols="12">
          <div v-if="plugin.attributes" class="sw-mb-7 sw-pb-3">
            <div class="sw-block-subtitle text-center">
              {{ plugin.attributes.questionwizard_introduction_headline }}
            </div>
            <v-card
              v-if="plugin.attributes.questionwizard_introduction_content"
              flat
              width="420"
              class="mx-auto text-center"
            >
              <nl2br
                tag="p"
                :text="plugin.attributes.questionwizard_introduction_content"
              />
            </v-card>
          </div>
          <div class="text-center">
            <v-btn
              rounded
              large
              depressed
              :style="{ backgroundColor: gmSecondaryColor }"
              class="
                text-none
                white--text
                sw-rounded
                sw-height-48px
              "
              @click="startQuiz"
            >
              <span class="sw-px-2">
                {{ startBtnText }}
              </span>
            </v-btn>
          </div>
        </v-col>
        <v-col v-if="isFormVisible" cols="12">
          <v-window v-model="window" class="sw-mb-7">
            <v-window-item
              v-for="(item, i) in windowItems"
              :key="`content-${i}`"
              :value="i"
            >
              <component :is="item.component" />
            </v-window-item>
          </v-window>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import Questions from "@/components/QuizPage/Questions.vue";
import Overview from "@/components/QuizPage/Overview.vue";
import Done from "@/components/QuizPage/Done.vue";

export default {
  data: () => ({
    isLoading: false,
    isFormVisible: false,
  }),
  computed: {
    user() {
      return this.$store?.getters?.user;
    },
    groupId() {
      return this.$store?.getters?.group?.id;
    },
    groupPluginId() {
      return this.$route?.params?.group_plugin_id;
    },
    plugin: {
      get() {
        return this.$store?.getters["quiz/plugin"];
      },
      set(val) {
        this.$store.commit("quiz/SET_PLUGIN", val);
      },
    },
    attribute: {
      get() {
        return this.$store?.getters["quiz/attribute"];
      },
      set(val) {
        this.$store.commit("quiz/SET_ATTRIBUTE", val);
      },
    },
    navigation() {
      return this.$store?.getters?.navigation?.destinations?.find(
        (el) =>
          el.component === "QuestionPage" &&
          el.group_plugin_id == this.groupPluginId,
      );
    },
    pageTitle() {
      return this.navigation?.name;
    },
    windowItems() {
      return [
        {
          component: Questions,
          enabled: true,
        },
        {
          component: Overview,
          enabled: true,
        },
        {
          component: Done,
          enabled: true,
        },
      ].filter((el) => el.enabled);
    },
    window: {
      get() {
        return this.$store?.getters["quiz/window"];
      },
      set(val) {
        this.$store.commit("quiz/SET_WINDOW", val);
      },
    },
    minHeight() {
      return { minHeight: `${this.$store?.getters?.windowHeight}px` };
    },
    startBtnText() {
      if (
        this.plugin.attributes &&
        this.plugin.attributes.questionwizard_start_button
      ) {
        return this.plugin.attributes.questionwizard_start_button;
      }

      return this.$vuetify.lang.t("$vuetify.startQuiz");
    },
  },
  watch: {
    groupPluginId: {
      immediate: true,
      handler() {
        this.attribute = {};
        this.fetchAll();
      },
    },
    window() {
      if (this.isFormVisible) this.$vuetify.goTo(".quiz-page");
    },
  },
  methods: {
    startQuiz() {
      this.isFormVisible = true;

      this.$vuetify.goTo(".quiz-page");
    },
    async getQuizPlugin() {
      const params = {
        with_attributes: 1,
      };

      const response = await this.$http.get(
        `/groups/${this.groupId}/plugins/${this.groupPluginId}`,
        {
          params,
        },
      );

      if (!response?.data?.data) return;

      this.plugin = response.data.data;
    },
    async getQuizProgress() {
      const params = {
        group_plugin_id: this.groupPluginId,
        per_page: 1,
      };

      const response = await this.$http.get(
        `/groups/${this.groupId}/users/attributes/categories`,
        { params },
      );

      const category = response?.data?.data?.[0];

      const params2 = {
        only_empty: 1,
        category_id: category.id,
      };

      const response2 = await this.$http.get(
        `/groups/${this.groupId}/users/attributes/next`,
        { params: params2 },
      );

      const isCompleted =
        response2?.data?.meta?.[`reserved_g${category.id}_completed`];

      if (!isCompleted) {
        this.window = 0;
      } else {
        this.window = 2;
        this.isFormVisible = true;
      }
    },
    async fetchAll() {
      if (!this.user.id) return;

      try {
        this.isLoading = true;

        await this.getQuizPlugin();
        await this.getQuizProgress();

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
